<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 <!-- ho_menu -->
	</div>
	<!-- menu_ico -->
	 <div class="about_lay chanye_bg1" id="cy1">
	   <div class="about_wapper" v-for="(item, index) in testdata1" :key="index">
	    <div class="piclay"><img :src="item.images" /></div>
	    <div class="fontlay">
<!--        <div class="pictitle"><img :src="item.images" /></div>-->
<!--		  <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
	    </div>
	   </div>
	 </div>
	 <!-- about_lay -->
	 <div class="about_lay chanye_bg2" id="cy2">
	   <div class="about_wapper" v-for="(item, index) in testdata2" :key="index">
       <div class="piclay"><img :src="item.images" /></div>
	    <div class="fontlay">
<!--	      <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
	    </div>
<!--	    <div class="piclay"><img src="@/assets/images/icon60.png" /></div>-->
	   </div>
	 </div>
	 <!-- 奶牛饲养 -->
	 <div class="about_lay chanye_bg3" id="cy3">
	   <div class="about_wapper" v-for="(item, index) in testdata3" :key="index">
       <div class="piclay"><img :src="item.images" /></div>
	    <div class="fontlay">
<!--	      <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
	    </div>
	   </div>
	 </div>
	 <!-- 产品研发 -->
	 <div class="about_lay chanye_bg4" id="cy4">
	   <div class="about_wapper" v-for="(item, index) in testdata4" :key="index">
       <div class="piclay"><img :src="item.images" /></div>
	    <div class="fontlay">
<!--	      <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
	    </div>
<!--       <div class="piclay"><img src="@/assets/images/icon64.png" /></div>-->
	   </div>
	 </div>
	 <!-- 乳品加工 -->
	 <div class="about_lay chanye_bg5" id="cy5">
	   <div class="about_wapper" v-for="(item, index) in testdata5" :key="index">
       <div class="piclay"><img :src="item.images" /></div>
	    <div class="fontlay">
<!--	      <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
	    </div>

	   </div>
	 </div>

  <!-- 质量管控 -->
  <div class="about_lay chanye_bg6" id="cy6">
    <div class="about_wapper" v-for="(item, index) in testdata6" :key="index">
      <div class="piclay"><img :src="item.images" /></div>
      <div class="fontlay">
<!--        <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
      </div>
    </div>
  </div>

  <!-- 冷链 -->
  <div class="about_lay chanye_bg7" id="cy7">
    <div class="about_wapper" v-for="(item, index) in testdata7" :key="index">
      <div class="piclay"><img :src="item.images" /></div>
      <div class="fontlay">
<!--        <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
      </div>
<!--      <div class="piclay"><img src="@/assets/images/icon81.png" /></div>-->
    </div>
  </div>

  <!-- 智慧营销 -->
  <div class="about_lay chanye_bg8" id="cy8">
    <div class="about_wapper" v-for="(item, index) in testdata8" :key="index">
      <div class="piclay"><img :src="item.images" /></div>
      <div class="fontlay">
<!--        <strong> {{item.title}}</strong>-->
        <p v-html="fh(item.description)"></p>
      </div>
<!--      <div class="piclay"><img src="@/assets/images/icon82.png" /></div>-->
    </div>
    <!-- 智慧营销 -->
  </div>



	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>

<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import {GETDATA1,GETDATA2,GETDATA3,GETDATA4,GETDATA5,GETDATA6,GETDATA7,GETDATA8} from '../apis/chanye.js'

import $ from "jquery/dist/jquery.min.js";
window.$ = $;
var doMore = function(index){
  $("#hide" +index).toggle();
  if($("#hide" +index).is(':hidden')){
    $("#a" +index).html("展开");
  } else {
    $("#a" +index).html("隐藏");
  }
}

$.m = doMore;


export default {
  name: 'about',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		testdata1:[],
		testdata2:[],
		testdata3:[],
		testdata4:[],
		testdata5:[],
		testdata6:[],
		testdata7:[],
		testdata8:[]
		
    };
  },
  mounted() {
    let metaInfo = {
      title: "产业布局",
      keywords: "辉山产业布局,农业种植,产业加工,奶牛饲养,产品研发,智慧营销",
      description: "辉山产业布局"
    }
    this.$store.commit("CAHNGE_META_INFO", metaInfo)
    console.log(this.$store.state.metaInfo)


  	  this.onloading();
      let url = window.location.href;
      if(url.indexOf("#") > 0){
        let id = url.substring( url.lastIndexOf("#")+1);
        if(id){
          document.getElementById(id).scrollIntoView(true);//$("html body").animate({scrollTop: id }, 1000);
        }
      }

  },
  methods: {
  	  async onloading(){
      this.testdata1 = await GETDATA1();
      this.testdata1 = this.testdata1.rows;
      this.testdata2 = await GETDATA2();
      this.testdata2 = this.testdata2.rows;
      this.testdata3 = await GETDATA3();
      this.testdata3 = this.testdata3.rows;
      this.testdata4 = await GETDATA4();
      this.testdata4 = this.testdata4.rows;
      this.testdata5 = await GETDATA5();
      this.testdata5 = this.testdata5.rows;
      this.testdata6 = await GETDATA6();
      this.testdata6 = this.testdata6.rows;
      this.testdata7 = await GETDATA7();
      this.testdata7 = this.testdata7.rows;
      this.testdata8 = await GETDATA8();
      this.testdata8 = this.testdata8.rows;

        $(".fontlay").each(function(index,data) {
          let str = $(this).html();
          let subStr = str.substring(0, 900);
          let subStrMore = str.substring(900, str.length);
          $(this).html(subStr + (str.length > 900 ? '' + "<span id=\"hide"+index+"\" style=\"display:none\">"+subStrMore+"</span><a id=\"a"+index+"\" onclick=\"$.m("+index+")\">展开</a>" : ''));
         });
  	  },
      fh(str){
          let rst = str.replace(/\n/g,"<p>");
          return rst;
      },
      doMore(index) {
        $("#hide" +index).toggle();
    }
  }
}
</script>