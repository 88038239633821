//home.js 用于获取首页json数据

import req from './http.js'  //引入封装好的axios

//在这里定义了一个登陆的接口，把登陆的接口暴露出去给组件使用
export const GETDATA1 =params=>req('get','cms/article/list/?catid=31&pageNum=1&pageSize=1',params)
export const GETDATA2 =params=>req('get','cms/article/list/?catid=32&pageNum=1&pageSize=1',params)
export const GETDATA3 =params=>req('get','cms/article/list/?catid=33&pageNum=1&pageSize=1',params)
export const GETDATA4 =params=>req('get','cms/article/list/?catid=34&pageNum=1&pageSize=1',params)
export const GETDATA5 =params=>req('get','cms/article/list/?catid=35&pageNum=1&pageSize=1',params)
export const GETDATA6 =params=>req('get','cms/article/list/?catid=330&pageNum=1&pageSize=1',params)
export const GETDATA7 =params=>req('get','cms/article/list/?catid=36&pageNum=1&pageSize=1',params)
export const GETDATA8 =params=>req('get','cms/article/list/?catid=37&pageNum=1&pageSize=1',params)
//这里使用了箭头函数，转换一下写法：
//export const GETTREEDATA=function(req){
//  return req('post','/GetTreeListForSoilByRegion',params)
//}
